import SensorGraph from "./components/SensorGraph";

function App() {
  return (
    <div className="App">
      <div className="sensors graph4x4">
        <SensorGraph />
      </div>
    </div>
  );
}

export default App;